.content-header {
  &__breadcrumbs {

  }

  &_article {
    &:not(._redesign) {
      border-radius: 16px;
      overflow: hidden;
      background: {
        image: url('../images/base/article-yellow-bg.png');
        repeat: no-repeat;
        size: cover;
        position: center;
      }
    }
  }

  &__title {
    font-family: var(--h-font);
    color: #FFFFFF;
  }

  &__description {
    padding-top: 24px;
    font-weight: 400;
    font-size: 17px;
    line-height: calc(26/17*1em);
    color: #FFFFFF;
  }

  &__info-wrapper {
    padding-top: 24px;
    display: flex;
  }

  &__type-of-article, &__date {
    display: flex;
  }

  &__icon {
    margin-right: 8px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__date {
    padding-left: 24px;
  }

  &__text {
    font-weight: 400;
    font-size: 15px;
    line-height: calc(24/15*1em);
    color: #FFFFFF;
  }
}

@include respond-up('large') {
  .content-header {
    &_article {
      padding: 88px 0 254px;
      margin: 0 24px;
    }

    &__title {
      font-weight: 700;
      font-size: 60px;
      line-height: calc(78/60*1em);
      grid-column: 3/11;
      text-align: center;
    }

    &__description {
      grid-column: 4/10;
      text-align: center;
    }

    &__info-wrapper {
      grid-column: 1/13;
      justify-content: center;
    }

    &__type-of-article {

    }

    &__date {

    }
  }
}

@include respond-down('medium') {
  .content-header {
    &__title, &__description, &__info-wrapper {
      grid-column: 1/5;
    }

    &_article {
      padding: 40px 24px 80px;
    }

    &__title {
      font-weight: 600;
      font-size: 28px;
      line-height: calc(36/28*1em);
    }

    &__description {

    }

    &__info-wrapper {

    }

    &__type-of-article {

    }

    &__date {

    }
  }
}