.about-page {
  &__upper-container {

  }

  &__text-container {

  }

  &__title {

  }

  &__description {
    font-weight: 400;
    font-size: 17px;
    line-height: calc(26/17*1em);
    color: #FFFFFF;
  }

  &__image-container {
    position: relative;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  &__workers-container {

  }
}

@include respond-up('large') {
  .about-page {
    padding: 72px 0 88px;

    &__upper-container {

    }

    &__text-container {
      grid-column: 1/7;
    }

    &__title {

    }

    &__description {
      padding-top: 48px;
    }

    &__image-container {
      grid-column: 8/13;
      max-height: 502px;
    }

    &__picture {

    }

    &__image {

    }

    &__workers-container {

    }
  }
}

@include respond-down('medium') {
  .about-page {
    padding: 40px 0 48px;

    &__upper-container {

    }

    &__text-container {
      grid-column: 1/5;
    }

    &__title {
      font-size: 30px;
    }

    &__description {
      padding-top: 24px;
    }

    &__image-container {
      margin-top: 40px;
      grid-column: 1/5;
      width: calc(288/320*100vw);
      height: calc(288/320*100vw);
      max-height: 350px;
    }

    &__picture {

    }

    &__image {

    }

    &__workers-container {

    }
  }
}