.main-cases {
  &__title {

  }

  &__list {

  }

  &__item {
    background: var(--background-opacity-60);

    &_case {
      border: 1px solid var(--background-opacity-60);
    }

    &:not(:last-child) {
      .main-cases {
        &__image {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background: {
            image: url('../images/base/main-page-case-image.png');
            repeat: no-repeat;
            size: cover;
            position: center;
          }
        }
      }
    }

    &:last-child {
      .main-cases {
        &__link {
          font-weight: 700;
          font-size: 17px;
          line-height: calc(28/17*1em);
        }
      }
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
  }

  &__image {
    display: block;
  }

  &__tag-name {
    font-family: var(--h-font);
    display: block;
    font-weight: 600;
    font-size: 24px;
    line-height: calc(30/24*1em);
    color: #FFFFFF;
  }

  &__detail {
    display: block;
    font-weight: 600;
    font-size: 17px;
    line-height: calc(24/17*1em);
    color: var(--secondary-white-text);
    margin-top: 6px;
  }

  &__link-text {
    font-family: var(--h-font);
    display: block;
    color: #FFFFFF;
  }

  &__image-wrapper {
    display: flex;
    position: relative;
  }
}

@include respond-up('large') {
  .main-cases {
    &__title {

    }

    &__list {
      grid-template-columns: repeat(15, 1fr);
      grid-column-gap: 24px;
      padding-top: 48px;
    }

    &__image-wrapper {
      padding-bottom: calc(300/300*100%);
      border-radius: 20px;
      overflow: hidden;
    }

    &__item {
      border-radius: 20px;

      &:first-child {
        grid-column: 1/5;
      }

      &:nth-child(2) {
        grid-column: 5/9;
      }

      &:nth-child(3) {
        grid-column: 9/13;
      }

      &:nth-child(4) {
        grid-column: 13/16;
      }

      &:last-child {
        .main-cases {
          &__link-text {
            transition: .4s color;
            text-align: center;
          }

          &__link {
            padding: 0 30px;
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;

            &:hover {
              .main-cases {
                &__link-text {
                  color: var(--background-yellow);
                }
              }
            }
          }
        }
      }

      &:not(:last-child) {
        .main-cases {
          &__link {
            &:hover {
              .main-cases {
                &__image {
                  transform: scale(1.05);
                  filter: grayscale(0);
                }

                &__detail {
                  color: var(--background-yellow);
                }
              }
            }
          }

          &__image {
            filter: grayscale(1);
            backface-visibility: hidden;
            transition: {
              property: transform, filter;
              duration: 1s;
            };
          }

          &__detail {
            transition: .4s color;
          }
        }
      }
    }

    &__link {

    }

    &__image {

    }

    &__tag-name {
      padding: 24px 28px 0;
    }

    &__detail {
      padding: 0 28px 28px;
    }

    &__link-text {
      font-weight: 600;
      font-size: 24px;
      line-height: calc(30/24*1em);
    }
  }
}

@include respond-down('medium') {
  .main-cases {
    &__title {

    }

    &__list {
      padding-top: 40px;
      grid-row-gap: 20px;
    }

    &__item {
      grid-column: 1/5;
      border-radius: 16px;
    }

    &__link {
      &_last {
        width: 100%;
        padding: 12px 0;
      }
    }

    &__image-wrapper {
      padding-bottom: calc(180/288*100vw);
      border-radius: 16px;
      overflow: hidden;
      max-height: 450px;
    }

    &__image {

    }

    &__tag-name {
      padding: 20px 16px 0 24px;
    }

    &__detail {
      padding: 0 16px 20px 24px;
    }

    &__link-text {
      text-align: center;
      font-weight: 700;
      border-radius: 10px;
    }
  }
}