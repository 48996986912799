:root {
  --default-input-height: 52px;
  --errors-color: var(--alert-color, #b50000)
}

form {
  $inputs: 'input[type="text"], input[type="search"], input[type="password"]';
  $allInputs: 'input[type="text"], input[type="search"], input[type="password"], select, textarea';

  label {
    font-size: 12px;
    color: #9f9f9f;
    margin-bottom: 2px;
    display: block;
  }

  ul.errors {
    margin-top: 6px;
    list-style: none;
    font-weight: 400;
    font-size: 13px;
    line-height: calc(20/13*1em);
    color: var(--red-color);

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  #{$allInputs}, textarea, select {
    border-radius: 10px;
    box-shadow: none;
    margin: 0;
    padding: 0 16px;
    width: 100%;
    display: block;
    appearance: none;
    font-family: var(--font);
    font-weight: 400;
    font-size: 17px;
    line-height: calc(26/17*1em);
    color: #FFFFFF;
    background: var(--background-opacity-64);
    border: unset;
  }

  #{$inputs}, select {
    height: var(--default-input-height);
  }

  textarea {
    padding: 5px 10px;
  }

  .button, button {
    padding: 0;
    margin: 0;
    text-align: center;
    transition: var(--default-transition);
    display: inline-block;
    border: none;
    background: var(--primary-color);
    font-size: 16px;
    cursor: pointer;

    &.height {
      height: var(--default-input-height);
    }
  }

  .input-wrapper {
    position: relative;

    #{$allInputs} {
      &.invalid {
        border: 1px solid var(--red-color);
      }
    }

    input::placeholder {
      color: #FFFFFF;
    }
  }
}