.cases {
  &__tags {

  }

  &__filter-form {
    .checkbox-list {
      padding: 0;
      margin: 0 -8px;
      display: flex;

      li {
        max-width: max-content;
        margin: 0 8px;
      }

      input {
        display: none;

        &:checked + label {
          background-color: var(--background-opacity-60);
          color: var(--primary-green-color);
        }
      }

      label {
        padding: 6px 16px;
        font-weight: 600;
        font-size: 17px;
        line-height: calc(24/17*1em);
        color: #FFFFFF;
        border-radius: 42px;
        cursor: pointer;
        transition: {
          property: background-color, color;
          duration: .4s;
        };

        &::before,
        &::after {
          display: none;
        }
      }
    }
  }

  &__bottom {

  }

  &__list {

  }

  &__item {

  }

  &__link {

  }

  &__image-container {
    display: block;
    border-radius: 24px;
    overflow: hidden;
    position: relative;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &__date {
    position: absolute;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 8px 12px;
    bottom: 16px;
    left: 16px;
    background: var(--background-opacity-64);
    border-radius: 14px;
  }

  &__text-container {

  }

  &__title {
    display: block;
    font-family: var(--h-font);
    font-weight: 600;
    font-size: 21px;
    line-height: calc(28/21*1em);
    color: #FFFFFF;
    margin-top: 16px;
  }

  &__tags-list {
    display: flex;
    margin: 0 -7px;
  }

  &__tags-item {
    display: block;
    position: relative;
    font-weight: 400;
    color: var(--secondary-white-text);
    padding: 0 7px;

    &:not(:last-child) {
      &::before {
        position: absolute;
        content: "";
        width: 2px;
        height: 2px;
        border-radius: 100px;
        background: var(--secondary-white-text);
        top: calc(50% - 1px);
        right: -1px;
      }
    }
  }

  &__more {
    font-weight: 700;
    font-size: 17px;
    line-height: calc(28/17*1em);
    font-family: var(--h-font);
    display: flex;
    align-items: center;
    width: fit-content;
    color: #000000;
    background: var(--primary-green-color);
    border-radius: 10px;
    height: 40px;
    padding: 0 49px;
    margin-top: 24px;
    transition: .4s opacity;
  }
}

@include respond-up('large') {
  .cases {
    padding: 36px 0 80px;

    &__tags {
      margin-bottom: 32px;
    }

    &__filter-form {
      .checkbox-list {
        label {
          &:hover {
            background-color: var(--background-opacity-60);
            color: var(--primary-green-color);
          }
        }
      }
    }

    &__bottom {

    }

    &__list {
      grid-row-gap: 64px;
    }

    &__item {
      &:first-child {
        grid-column: 1/13;
        max-height: 391px;

        .cases {
          &__title {
            font-weight: 600;
            font-size: 44px;
            line-height: calc(56/44*1em);
            color: #FFFFFF;
            margin-top: 0;
          }

          &__text-container {
            padding-left: 30px;
          }
        }
      }

      &:nth-child(3n+2) {
        grid-column: 1/5;
      }

      &:nth-child(3n+3) {
        grid-column: 5/9;
      }

      &:nth-child(3n+4) {
        grid-column: 9/13;
      }

      &:not(:first-child) {
        .cases {
          &__image-container {
            position: relative;
            padding-bottom: 450/384*100%;
          }

          &__picture {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
      }
    }

    &__image-container {

    }

    &__link {
      height: 100%;

      &_grid {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 32px;

        .cases {
          &__image-container {
            grid-column: 1/8;
            border-radius: 20px;
          }

          &__text-container {
            grid-column: 8/13;
          }
        }
      }

      &:hover {
        .cases {
          &__title {
            color: var(--primary-green-color);
          }

          &__more {
            opacity: 0.88;
          }
        }
      }
    }

    &__image {

    }

    &__picture {

    }

    &__date {

    }

    &__text-container {

    }

    &__title {
      transition: .4s color;
    }

    &__tags-list {
      padding-top: 8px;
    }

    &__tags-item {
      font-size: 17px;
      line-height: calc(26/17*1em);
    }

    &__more {

    }
  }
}

@include respond-down('medium') {
  .cases {
    padding: 16px 0 48px;

    &__tags {
      margin: 0 10px 16px;
    }

    &__filter-form {
      flex-wrap: nowrap;
      margin: 0 -25px;
      overflow-x: scroll;
      padding: 0 12px;

      &::-webkit-scrollbar {
        display: none;
      }

      .checkbox-list {
        label {
          white-space: nowrap;
        }
      }
    }

    &__bottom {

    }

    &__list {
      grid-row-gap: 40px;
    }

    &__item {
      grid-column: 1/5;
    }

    &__link {

    }

    &__image-container {
      width: calc(288/320*100vw);
      height: calc(288/320*100vw);
      max-height: 400px;
    }

    &__image {

    }

    &__picture {

    }

    &__date {

    }

    &__text-container {

    }

    &__title {

    }

    &__tags-list {
      padding-top: 4px;
    }

    &__tags-item {
      font-size: 15px;
      line-height: calc(24/15*1em);
    }

    &__more {
      display: none;
    }
  }
}