.uikit-page {
  &__element-desc {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background: var(--primary-color);
    color: white;
  }

  &__elements-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@include respond-up('large') {
  .uikit-page {
    &__element {
      flex: 0 0 48%;
      margin-bottom: 50px;
    }
  }
}

@include respond('medium') {
  .uikit-page {

  }
}

@include respond-down('medium') {
  .uikit-page {
    &__element {
      flex: 0 0 100%;
      margin-bottom: 40px;
    }
  }
}

@include respond-down('small') {
  .uikit-page {

  }
}
