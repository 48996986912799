.main-more {
  &__title {

  }

  &__list {

  }

  &__item {

  }

  &__link {
    background: var(--background-opacity-60);
    border: 1px solid var(--background-opacity-60);
    overflow: hidden;
  }

  &__image-container {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(1);
  }

  &__picture {
    display: flex;
    height: 100%;
  }

  &__caption {
    font-family: var(--h-font);
    display: block;
    position: relative;
    z-index: 10;
    color: #FFFFFF;

    &_yellow {
      color: var(--background-yellow);
    }

    &_pink {
      color: var(--pink-color);
    }
  }

  &__description {
    display: block;
    position: relative;
    z-index: 10;
    padding-top: 16px;
    font-weight: 500;
    font-size: 20px;
    line-height: calc(28/20*1em);
    color: #FFFFFF;
  }

  &__pseudo-button {
    font-family: var(--h-font);
    position: relative;
    z-index: 10;
    color: #000000;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    align-items: center;

    &_pink {
      &:hover {
      }
    }
  }
}

@include respond-up('large') {
  .main-more {
    &__title {

    }

    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 32px;
      padding-top: 48px;
    }

    &__item {

    }

    &__link {
      display: flex;
      flex-direction: column;
      position: relative;
      min-height: 592px;
      border-radius: 24px;
      padding: 72px;

      &:hover {
        .main-more {
          &__image {
            filter: none;
          }

          &__pseudo-button {
            background: var(--background-yellow);
            color: #000000;

            &_pink {
              background-color: var(--pink-color);
              color: #FFFFFF;
            }
          }
        }
      }
    }

    &__image-container {
    }

    &__image {
      transition: 1s;
    }

    &__picture {

    }

    &__caption {
      font-weight: 700;
      font-size: 64px;
      line-height: calc(76/64*1em);
    }

    &__description {

    }

    &__pseudo-button {
      font-weight: 700;
      font-size: 17px;
      line-height: calc(28/17*1em);
      padding: 0 32px;
      width: fit-content;
      height: 52px;
      margin-top: 48px;
      transition: {
        property: background-color, color;
        duration: .4s;
      };
    }
  }
}

@include respond-down('medium') {
  .main-more {
    &__title {

    }

    &__list {
      display: flex;
      flex-direction: column;
      padding-top: 40px;
    }

    &__item {

    }

    &__link {
      display: block;
      border-radius: 16px;
      padding: 32px;
      position: relative;
      min-height: 280px;

      &:first-child {
        margin-bottom: 16px;
      }
    }

    &__image-container {
      //display: block;
      //position: relative;
      //width: 100%;
      //padding-bottom: calc(188/288*100%);
    }

    &__image {
      opacity: .6;
    }

    &__picture {
      //position: absolute;
      //top: 0;
      //right: 0;
      //left: 0;
      //bottom: 0;
    }

    &__caption {
      font-weight: 600;
      font-size: 28px;
      line-height: calc(36/28*1em);
    }

    &__description {

    }

    &__pseudo-button {
      font-weight: 700;
      font-size: 17px;
      line-height: calc(28/17*1em);
      height: 40px;
      width: 100%;
      margin-top: 40px;
      justify-content: center;
    }
  }
}