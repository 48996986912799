.materials-page {
  &__list {

  }

  &__item {

  }

  &__link {
    height: 100%;
  }

  &__image-container {
    overflow: hidden;
    display: block;
    position: relative;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &__date {
    position: absolute;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 8px 12px;
    bottom: 16px;
    left: 16px;
    background: var(--background-opacity-64);
    border-radius: 14px;
  }

  &__text-container {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-family: var(--h-font);
    color: #FFFFFF;
    font-weight: 600;
  }

  &__more {
    font-weight: 700;
    font-size: 17px;
    line-height: calc(28/17*1em);
    font-family: var(--h-font);
    display: flex;
    align-items: center;
    width: fit-content;
    color: var(--pink-color);
    border-radius: 10px;
    height: 40px;
    padding: 0 49px;
    margin-top: 24px;
    transition: .4s opacity;
    background: var(--background-opacity-60);
  }

  &__more-description {
    font-family: var(--h-font);
    color: #FFFFFF;
    font-weight: 600;
    margin-top: 16px;
  }
}

@include respond-up('large') {
  .materials-page {
    padding: 64px 0 88px;

    &__list {
      grid-row-gap: 32px;
    }

    &__item {
      grid-column: 1/13;

      &:first-child {
        max-height: fit-content;

        .materials-page {
          &__image-container {
            grid-column: 1/8;
            border-radius: 20px;
          }

          &__text-container {
            grid-column: 8/13;

          }

          &__title {
            font-size: 44px;
            line-height: calc(56/44*1em);
          }

          &__more {
            color: #FFFFFF;
            background: var(--pink-color);
          }
        }
      }

      &:not(:first-child) {
        background: var(--background-opacity-60);
        border: 1px solid var(--background-opacity-60);
        border-radius: 20px;

        .materials-page {
          &__link {
            padding: 8px;
          }

          &__text-container {
            padding: 38px 48px 38px 0;
          }
        }
      }
    }

    &__link {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 32px;

      &:hover {
        .materials-page {
          &__title {
            color: var(--pink-color);
          }
        }
      }
    }

    &__image-container {
      grid-column: 1/7;
      border-radius: 16px;
    }

    &__image {

    }

    &__picture {

    }

    &__date {

    }

    &__text-container {
      grid-column: 7/13;
      padding: 38px 0 16px;
    }

    &__title {
      font-size: 28px;
      line-height: calc(36/28*1em);
      transition: .4s color;
    }

    &__more {
      margin-top: 24px;
    }
  }
}

@include respond-down('medium') {
  .materials-page {
    padding: 16px 0 48px;

    &__list {
      grid-row-gap: 16px;
    }

    &__item {
      grid-column: 1/5;
      background: var(--background-opacity-60);
      border: 1px solid var(--background-opacity-60);
      border-radius: 20px;
    }

    &__link {
      display: block;
      padding: 8px 8px 16px;
    }

    &__image-container {
      border-radius: 20px;
    }

    &__image {

    }

    &__picture {

    }

    &__date {

    }

    &__text-container {
      padding: 0 8px;
    }

    &__title {
      font-size: 21px;
      line-height: calc(26/21*1em);
      padding-top: 16px;
    }

    &__more {
      margin-top: 14px;
      padding: 0 12px;
    }
  }
}