.services-slider-container {
  &__inner {

  }

  &__title {
    font-weight: 600;
    color: #FFF;
  }

  &__container {
    position: relative;
  }

  &__text {
    position: relative;
    z-index: 10;
    font-size: 17px;
    font-weight: 600;
    line-height: calc(24/17*1em);
    color: #FFF;
    border-radius: 20px 20px 0 0;
    background: var(--background-main-gray);
    min-height: 130px;
  }

  &__service-link {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    border: 1px solid var(--background-opacity-08);
    overflow: hidden;
  }

  &__image-wrapper {
    position: relative;
    z-index: 5;
  }

  &__image {
    width: 100%;
  }

  &__picture {
    display: flex;
    position: relative;
    z-index: 5;
  }

  &__image-transparent-layer {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--background-opacity-08);
  }

  &__item {

  }

  &__arrows {
    height: 1px;
    position: absolute;
    left: -24px;
    right: -24px;
    top: 45%;
    transform: translateY(-50%);
    z-index: 50;
  }
}

@include respond-up('large') {
  .services-slider-container {
    margin-bottom: 80px;

    &__inner {
      margin-top: 64px;
      border-top: 1px solid var(--background-opacity-08);
    }

    &__title {
      padding: 24px 0 32px;
    }

    &__text {
      padding: 20px;
      margin-top: -16px;
      transition: color 0.4s var(--default-transition-function);
    }

    &__service-link {
      &:hover {
        .services-slider-container {
          &__text {
            color: var(--background-yellow);
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .services-slider-container {
    margin-bottom: 40px;
    overflow: hidden;

    &__title {
      padding: 40px 0 16px;
    }

    &__text {
      padding: 16px 20px;
      margin-top: -16px;
    }

    &__container {
      position: relative;

      .tns-outer {
        margin: 0 -16px;
      }

      .tns-inner {
        margin: 0 16px !important;
      }
    }
  }
}