.index-page {
  &__about {

  }

  &__cases {

  }

  &__more {

  }

  &__map {
    filter: grayscale(100%) invert(100%);
  }

  &__map-title {
    font-family: var(--h-font);
    font-weight: 600;
    color: white;
    margin-bottom: 45px;
  }

  &__map-point {
    z-index: 10000;
  }

  &__map-wrapper {
    position: relative;
  }

  &__name {
    background-color: white;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    border-radius: 0 30px 30px 0;
    padding: 4px 12px 4px 20px;
    z-index: 1;
    display: flex;
    align-items: center;
  }

  &__image {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: -17px;
    z-index: 20;
  }

  &__map-link {
    display: flex;
  }

  &__map {
    //pointer-events: none;
  }
}

@include respond-up('large') {
  .index-page {
    padding: 104px 0 80px;

    &__about {

    }

    &__cases {
      padding-top: 96px;
    }

    &__more {
      padding-top: 120px;
    }

    &__map-wrapper {

    }

    &__map {
      height: 550px;
    }

    &__map-point-list {
      display: block;

      &_small {
        display: none;
      }
    }

    &__map-title {
      margin-top: 120px;
    }
  }
}

@include respond-down('medium') {
  .index-page {
    padding: 48px 0;

    &__about {

    }

    &__cases {
      padding-top: 80px;
    }

    &__more {
      padding-top: 80px;
    }

    &__map-point-list {
      display: none;
      &_small {
        display: block;
      }
    }

    &__map-wrapper {
      overflow: hidden;
    }

    &__map {
      height: 400px;
    }

    &__map-title {
      font-size: 34px;
      margin-top: 80px;
    }
  }
}