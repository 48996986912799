.article {
  &__inner {

  }

  &__image-container {

  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture {
    display: flex;
    width: 100%;
    border-radius: 24px;
    overflow: hidden;
  }

  &__anchors-container {
    margin-top: 32px;
  }

  &__anchors-list {
    margin-top: 12px;
  }

  &__anchors-item {
    &:not(:last-child) {
      .article {
        &__anchors-link {
          margin-bottom: 12px;
        }
      }
    }
  }

  &__anchors-caption {
    font-weight: 600;
    font-size: 17px;
    line-height: calc(27/17*1em);
    color: #FFFFFF;
  }

  &__anchors-inner {
    display: flex;
    flex-direction: column;
  }

  &__anchors-link {
    display: block;
    width: fit-content;
  }

  &__anchors-link-text {
    border-bottom: 1px dashed var(--background-yellow);
    font-weight: 400;
    font-size: 15px;
    line-height: calc(24/15*1em);
    color: var(--background-yellow);

    &_green {
      color: var(--primary-green-color);
      border-bottom: 1px dashed var(--primary-green-color);
    }

    &_pink {
      color: var(--pink-color);
      border-bottom: 1px dashed var(--pink-color);
    }

    &_yellow {
      color: var(--background-yellow);
      border-bottom: 1px dashed var(--background-yellow);
    }
  }

  &__caption {
    color: var(--background-yellow);
  }

  &__back-link {
    display: flex;
    align-items: center;
  }

  &__back-link-icon {
    display: block;
    width: 44px;
    height: 44px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__back-link-text {
    display: block;
    padding-left: 16px;
    font-weight: 600;
    font-size: 17px;
    line-height: calc(24/17*1em);
    color: #FFFFFF;
  }
}

@include respond-up('large') {
  .article {
    margin: -170px 0 88px;

    &._redesign {
      margin: 0 0 64px;
      padding-top: 16px;
    }

    &__inner {
      grid-column: 1/13;
    }

    &__image-container {

    }

    &__image {

    }

    &__picture {
      height: calc(500/1440*100vw);
      max-height: 600px;
    }

    &__anchors-container {

    }

    &__anchors-caption {

    }

    &__anchors-inner {
      background: var(--background-opacity-60);
      border-radius: 20px;
      overflow: hidden;
      grid-column: 3/11;
      padding: 24px 32px 32px;
    }

    &__anchors-list {

    }

    &__anchors-item {

    }

    &__back-link {
      grid-column: 3/13;

      &:hover {
        .article {
          &__back-link-text {
            color: var(--secondary-white-text);
          }

          &__back-link-icon {
            svg {
              g {
                rect {
                  fill: var(--secondary-white-text);
                }
              }
            }
          }
        }
      }
    }

    &__back-link-icon {
      svg {
        g {
          rect {
            transition: .4s fill;
          }
        }
      }
    }

    &__back-link-text {
      transition: .4s color;
    }

    &__caption {
      margin-top: 24px;
      grid-column: 3/13;
    }
  }
}

@include respond-down('medium') {
  .article {
    margin: -50px 0 48px;

    &._redesign {
      margin: 0 0 64px;
    }

    &__inner {
      grid-column: 1/5;
    }

    &__image-container {

    }

    &__image {

    }

    &__picture {
      max-height: 350px;
    }

    &__anchors-container {

    }

    &__anchors-inner {
      grid-column: 1/5;
    }

    &__anchors-caption {

    }

    &__anchors-list {

    }

    &__anchors-item {

    }

    &__back-link {
      grid-column: 1/5;
    }

    &__caption {
      margin-top: 16px;
      grid-column: 1/5;
    }
  }
}