/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/
$spacer-small: 16;
$spacer-large: 24;

$grid: (
  small: (
    from: 0,
    to: 767,
    spacer: #{$spacer-small}
  ),
  medium: (
    from: 768,
    to: 1259,
    spacer: #{$spacer-small}
  ),
  large: (
    from: 1260,
    spacer: #{$spacer-large}
  )
);

:root {
  --max-row-width: 1216;

  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "IBM Plex Sans", sans-serif;
  --h-font: "Montserrat", sans-serif;

  --t: transparent;

  --primary-color: #01c767;
  --primary-green-color: #A4FD01;

  --alert-color: #b50000;
  --default-transition: all 0.4s var(--default-transition-function);
  --default-transition-function: ease-in-out;
  --primary-color-opacity: rgba(1, 199, 103, .85);

  --secondary-white-text: #A9ADB8;
  --gray-text: #787D83;
  --red-color: #FE1B25;
  --pink-color: #FD017A;

  --filter-color: grayscale(100%) invert(90%);

  --background-main-black: #0F0F0F;
  --background-main-gray: #1D1D1D;
  --background-yellow: #FDBC0B;
  --background-opacity-60: rgba(255, 255, 255, 0.06);
  --background-opacity-64: rgba(0, 0, 0, 0.64);
  --background-opacity-08: rgba(255, 255, 255, 0.08);
}
