.service-offer {
  overflow: hidden;
  position: relative;

  &__inner {
    position: relative;
    z-index: 10;
  }

  &__title {

  }

  &__image-container {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__back-link {
    z-index: 10;
    display: flex;
    align-items: center;
  }

  &__back-link-icon {
    display: block;
    width: 44px;
    height: 44px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__back-link-text {
    display: block;
    padding-left: 16px;
    font-weight: 600;
    font-size: 17px;
    line-height: calc(24/17*1em);
    color: #FFFFFF;
  }
}

@include respond-up('large') {
  .service-offer {
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    height: 450px;
    margin: 0 24px;
    border-radius: 24px;
    background: var(--background-opacity-60);

    &__inner {
      border-radius: 24px;
    }

    &__title {
      grid-column: 3/11;
      text-align: center;
    }

    &__image-container {

    }

    &__picture {

    }

    &__image {

    }

    &__back-link {
      position: absolute;
      top: 32px;
      left: 32px;

      &:hover {
        .service-offer {
          &__back-link-text {
            color: var(--secondary-white-text);
          }

          &__back-link-icon {
            svg {
              g {
                rect {
                  fill: var(--secondary-white-text);
                }
              }
            }
          }
        }
      }
    }

    &__back-link-icon {
      svg {
        g {
          rect {
            transition: .4s fill;
          }
        }
      }
    }

    &__back-link-text {
      transition: .4s color;
    }
  }
}

@include respond-down('medium') {
  .service-offer {
    padding: 32px 24px 56px;
    border-radius: 20px;
    overflow: hidden;

    &__inner {

    }

    &__title {
      padding-top: 16px;
      grid-column: 1/5;
      font-size: 26px;
    }

    &__image-container {

    }

    &__picture {

    }

    &__image {

    }

    &__back-link {
      position: relative;
    }

    &__back-link-icon {

    }

    &__back-link-text {

    }
  }
}