h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
}

h1, .h1 {
  font-weight: 700;
  color: #FFFFFF;
}

h2, .h2 {
  font-weight: 600;
  color: #FFFFFF;
}

h3, .h3 {
  --h-size: 26px;
}

h4, .h4 {
  --h-size: 24px;
}

h5, .h5 {
  --h-size: 22px;
}

h6, .h6 {
  --h-size: 20px;
}

@include respond-up('large') {
  h1 {
    font-size: 64px;
    line-height: calc(76/64*1em);
  }

  h2 {
    font-size: 44px;
    line-height: calc(56/44*1em);
  }

  h3 {
    font-size: 28px;
    line-height: calc(36/28*1em);
  }
}

@include respond-down('medium') {
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    word-wrap: break-word;
  }

  h1 {
    font-size: 36px;
    line-height: calc(44/36*1em);
  }

  h2 {
    font-size: 34px;
    line-height: calc(44/34*1em);
  }

  h3 {
    font-size: 28px;
    line-height: calc(36/28*1em);
  }
}