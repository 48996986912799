.workers {
  &__title {

  }

  &__list {

  }

  &__item {

  }

  &__link {
    display: flex;
  }

  &__name {
    font-family: var(--h-font);
    color: #FFFFFF;
  }

  &__post {
    font-weight: 400;
  }

  &__image-wrapper {

  }

  &__picture {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
  }
}

@include respond-up('large') {
  .workers {
    padding-top: 120px;

    &__title, &__list {
      grid-column: 1/13;
    }

    &__list {
      padding-top: 48px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 64px;
    }

    &__item {

    }

    &__link {
      position: relative;
      height: 100%;
      min-height: 280px;
    }

    &__text-wrapper {
      position: relative;
      z-index: 20;
      padding: 178px 20px 20px;
    }

    &__name {
      font-weight: 600;
      font-size: 21px;
      line-height: calc(26/21*1em);

      &_mobile {
        display: none;
      }
    }

    &__post {
      font-size: 14px;
      line-height: calc(26/17*1em);
      color: #FFFFFF;
      padding-top: 4px;
    }

    &__image-wrapper {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &__picture {
      &_mobile {
        display: none;
      }
    }

    &__image {
      border-radius: 12px;
    }

    &__shadow-box {
      position: absolute;
      border-radius: 12px;
      z-index: 15;
      height: 166px;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.00622598) 6.67%, rgba(0, 0, 0, 0.0255672) 13.33%, rgba(0, 0, 0, 0.0587951) 20%, rgba(0, 0, 0, 0.106136) 26.67%, rgba(0, 0, 0, 0.166878) 33.33%, rgba(0, 0, 0, 0.238956) 40%, rgba(0, 0, 0, 0.318738) 46.67%, rgba(0, 0, 0, 0.401262) 53.33%, rgba(0, 0, 0, 0.481044) 60%, rgba(0, 0, 0, 0.553122) 66.67%, rgba(0, 0, 0, 0.613864) 73.33%, rgba(0, 0, 0, 0.661205) 80%, rgba(0, 0, 0, 0.694433) 86.67%, rgba(0, 0, 0, 0.713774) 93.33%, rgba(0, 0, 0, 0.72) 100%);
    }
  }
}

@include respond-down('medium') {
  .workers {
    padding-top: 64px;

    &__title, &__list {
      grid-column: 1/5;
    }

    &__list {
      padding-top: 44px;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    &__link {
      align-items: center;
    }

    &__text-wrapper {
      order: 2;
    }

    &__name {
      font-weight: 500;
      font-size: 20px;
      line-height: calc(28/20*1em);

      &_desktop {
        display: none;
      }
    }

    &__post {
      font-size: 15px;
      line-height: calc(24/15*1em);
      color: var(--secondary-white-text);
    }

    &__image-wrapper {
      order: 1;
      width: calc(80/320*100vw);
      height: calc(80/320*100vw);
      margin-right: 16px;
      position: relative;
    }

    &__picture {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }

    &__image {
      border-radius: 16px;
    }

    &__shadow-box {
      display: none;
    }

    &__picture {
      &_desktop {
        display: none;
      }
    }
  }
}