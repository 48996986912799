.facilities-page {
  &__list {

  }

  &__item {

  }

  &__link {
    display: flex;
    flex-direction: column;
  }

  &__image-container {
    display: block;
    border-radius: 16px;
    overflow: hidden;
  }

  &__picture {
    display: flex;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title {
    display: block;
    color: #FFFFFF;
    font-weight: 600;
    padding-top: 16px;
    font-family: var(--h-font);
  }

  &__price {
    display: block;
    font-weight: 400;
    font-size: 15px;
    line-height: calc(24/15*1em);
    color: var(--secondary-white-text);
    padding-top: 4px;
  }

  &__button {
    font-weight: 700;
    font-size: 17px;
    line-height: calc(28/17*1em);
    font-family: var(--h-font);
    display: flex;
    align-items: center;
    width: fit-content;
    color: var(--background-yellow);
    margin-top: 14px;
    height: 40px;
    padding: 0 16px;
    background: var(--background-opacity-60);
    border-radius: 10px;
  }

  &__text-container {
    display: flex;
    flex-direction: column;
  }
}

@include respond-up('large') {
  .facilities-page {
    padding: 56px 0 88px;

    &__list {
      grid-row-gap: 32px;
    }

    &__item {
      &:first-child {
        grid-column: 1/7;
      }

      &:nth-child(2) {
        grid-column: 7/13;
      }

      &:nth-child(3n + 3) {
        grid-column: 1/5;
      }

      &:nth-child(3n + 4) {
        grid-column: 5/9;
      }

      &:nth-child(3n + 5) {
        grid-column: 9/13;
      }
    }

    &__link {
      padding: 8px 8px 16px;
      background: var(--background-opacity-60);
      border: 1px solid var(--background-opacity-60);
      border-radius: 20px;
      height: 100%;

      &:hover {
        .facilities-page {
          &__image-container {
            opacity: 1;
          }

          &__title {
            color: var(--background-yellow);
          }
        }
      }
    }

    &__image-container {
      opacity: 0.8;
      transition: .4s opacity;
      height: 368px;
    }

    &__picture {
      position: relative;
      z-index: 5;
    }

    &__image {

    }

    &__title {
      font-size: 21px;
      line-height: calc(26/21*1em);
      transition: .4s color;
    }

    &__price {

    }

    &__button {

    }

    &__text-container {
      max-width: 360px;
      padding-left: 12px;
    }
  }
}

@include respond-down('medium') {
  .facilities-page {
    padding: 16px 0 48px;

    &__list {
      grid-row-gap: 16px;
    }

    &__item {
      grid-column: 1/5;

      &:first-child {
        .facilities-page {
          &__link {
            padding: 8px 8px 24px;
          }

          &__text-container {
            padding-left: 8px;
          }
        }
      }

      &:not(:first-child) {
        .facilities-page {
          &__link {
            display: flex;
            flex-direction: row;
            padding: 16px;
          }

          &__image-container {
            order: 2;
            margin-left: 12px;
            height: 96px;
            min-width: 96px;
          }

          &__text-container {
            order: 1;
          }

          &__title {
            padding-top: 0;
          }
        }
      }
    }

    &__link {
      background: var(--background-opacity-60);
      border: 1px solid var(--background-opacity-60);
      border-radius: 20px;
    }

    &__image-container {

    }

    &__picture {

    }

    &__image {

    }

    &__title {
      font-size: 21px;
      line-height: calc(26/21*1em);
    }

    &__price {

    }

    &__button {

    }

    &__text-container {

    }
  }
}