.partner {
  &__image-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image-background {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: {
      image: url('../images/base/modal-background.png');
      repeat: no-repeat;
      size: cover;
      position: center;
    }
  }

  &__text-container {

  }

  &__name {
    color: #FFFFFF;
  }

  &__city {
    font-weight: 400;
    font-size: 17px;
    line-height: calc(26/17*1em);
    color: var(--secondary-white-text);
    padding-top: 4px;
  }

  &__text {
    font-weight: 400;
    color: #FFFFFF;
  }

  &__case-link {
    font-weight: 700;
    font-size: 17px;
    line-height: calc(28/17*1em);
    font-family: var(--h-font);
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 0 16px;
    height: 40px;
    color: #000000;
    background: var(--background-yellow);
    border-radius: 10px;
    margin-top: 32px;
    transition: .4s opacity;
  }

  &__picture {
    display: flex;
  }

  &__image {

  }
}

@include respond-up('large') {
  .partner {
    display: flex;
    width: 886px;

    &__image-wrapper {
      min-height: 578px;
      max-height: fit-content;
      min-width: 350px;
      max-width: 350px;
    }

    &__image-background {

    }

    &__text-container {
      padding: 64px 64px 40px;
    }

    &__name {

    }

    &__city {

    }

    &__text {
      padding-top: 32px;
      font-size: 20px;
      line-height: calc(28/20*1em);
    }

    &__case-link {
      &:hover {
        opacity: 0.88;
      }
    }

    &__image-background {
      border-radius: 24px 0 0 24px;
    }
  }
}

@include respond-down('medium') {
  .partner {
    &__image-wrapper {
      width: 320/320*100vw;
      height: 320px;
      max-height: 320px;
      position: relative;
    }

    &__image-background {

    }

    &__text-container {
      padding: 20px 16px 40px;
    }

    &__name {

    }

    &__city {

    }

    &__text {
      padding-top: 20px;
      font-size: 17px;
      line-height: calc(26/17*1em);
    }

    &__case-link {

    }

    &__image {
      min-width: 240px;
      min-height: 39px;
    }
  }
}