.request-modal-form {
  display: flex;
  flex-direction: column;
  position: relative;

  &__success {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__data {
    position: relative;
    z-index: 1;
    transition: all 0.4s;
  }

  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: calc(36/28*1em);
    text-align: center;
    color: #FFFFFF;
  }

  &__description {
    margin-top: 10px;
    font-size: 20px;
    line-height: calc(28/20*1em);
    text-align: center;
    color: #FFFFFF;
    font-weight: 600;
  }

  &__fields {
    margin-top: 40px;
  }

  &__button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__button {
    font-family: var(--h-font);
    background: var(--primary-green-color);
    border-radius: 10px;
    font-weight: 700;
    font-size: 17px;
    line-height: calc(28/17*1em);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    width: 100%;
  }

  &.success {
    .request-modal-form {
      &__data {
        opacity: 0;
      }

      &__success {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(0,0,0);
      }
    }
  }

  &__success {
    opacity: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__success-title {
    font-weight: 600;
    font-size: 28px;
    line-height: calc(36/28*1em);
    text-align: center;
    color: #FFFFFF;
  }

  &__success-subtitle {
    font-weight: 400;
    font-size: 17px;
    line-height: calc(26/17*1em);
    text-align: center;
    color: #A9ADB8;
    margin-top: 8px;
  }

  &__policy {
    font-weight: 400;
    font-size: 13px;
    line-height: calc(20/13*1em);
    text-align: center;
    color: var(--gray-text);
    max-width: 220px;
    margin-top: 20px;
  }

  &__policy-link {
    border-bottom: 1px solid var(--gray-text);
  }
}

@include respond-up('large') {
  .request-modal-form {
    padding: 64px;
    width: 468px;

    &__success {
      height: 468px;
    }

    &__data {

    }

    &__title {

    }

    &__fields {

    }

    &__button-wrapper {

    }

    &__button {

    }

    &__success {

    }

    &__success-title {

    }

    &__success-subtitle {

    }
  }
}

@include respond-down('medium') {
  .request-modal-form {
    &__data {
      padding: 0 20px;
    }

    &__title {

    }

    &__fields {

    }

    &__button-wrapper {

    }

    &__button {

    }

    &__success {

    }

    &__success-title {

    }

    &__success-subtitle {

    }
  }
}