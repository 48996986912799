.socials {
  margin: 0 -6px;
  display: flex;

  &_header {
    .socials {
      &__link {
        margin: 0;
        padding: 10px;

      }
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &__item {

  }

  &__link {
    display: block;
    padding: 0 6px;
  }
}

@include respond-up('large') {
  .socials {
    &__link {
      &:hover {
        .socials {
          &__icon {
            transform: scale(1.2);
          }
        }
      }
    }

    &__icon {
      transition: .4s transform;
    }
  }
}

@include respond-down('medium') {
  .socials {

  }
}