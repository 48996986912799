.worker {
  &__image-wrapper {

  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-container {

  }

  &__name {
    color: #FFFFFF;
  }

  &__post {
    font-weight: 400;
    font-size: 17px;
    line-height: calc(26/17*1em);
  }

  &__text {
    font-weight: 400;
    color: #FFFFFF;
  }
}

@include respond-up('large') {
  .worker {
    display: flex;
    max-width: 886px;
    overflow: hidden;

    &__image-wrapper {
      //height: 100%;
      //max-height: 525px;
      min-width: 350px;
      max-width: 350px;
    }

    &__picture {

    }

    &__image {
      border-radius: 24px 0 0 24px;
    }

    &__text-container {
      padding: 64px 64px 64px 40px;
      min-width: 500px;
    }

    &__name {
      &_mobile {
        display: none;
      }
    }

    &__post {
      padding-top: 4px;
      color: var(--secondary-white-text);

      &_mobile {
        display: none;
      }
    }

    &__text {
      font-size: 20px;
      line-height: calc(28/20*1em);
      padding-top: 24px;
    }

    &__shadow-box {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .worker {
    &__image-wrapper {
      width: 320/320*100vw;
      height: 100vh;
      max-height: 500px;
      position: relative;
    }

    &__shadow-box {
      position: absolute;
      z-index: 15;
      left: 0;
      bottom: 0;
      right: 0;
      height: calc(186/320*100vw);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.00622598) 6.67%, rgba(0, 0, 0, 0.0255672) 13.33%, rgba(0, 0, 0, 0.0587951) 20%, rgba(0, 0, 0, 0.106136) 26.67%, rgba(0, 0, 0, 0.166878) 33.33%, rgba(0, 0, 0, 0.238956) 40%, rgba(0, 0, 0, 0.318738) 46.67%, rgba(0, 0, 0, 0.401262) 53.33%, rgba(0, 0, 0, 0.481044) 60%, rgba(0, 0, 0, 0.553122) 66.67%, rgba(0, 0, 0, 0.613864) 73.33%, rgba(0, 0, 0, 0.661205) 80%, rgba(0, 0, 0, 0.694433) 86.67%, rgba(0, 0, 0, 0.713774) 93.33%, rgba(0, 0, 0, 0.72) 100%);
    }

    &__mobile-name-container {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 20;
      padding: 0 20px 20px;
    }

    &__name {
      &_desktop {
        display: none;
      }
    }

    &__post {
      &_desktop {
        display: none;
      }
    }

    &__picture {
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }

    &__image {

    }

    &__text-container {
      padding: 20px 16px 40px;
    }

    &__name {

    }

    &__post {
      color: #FFFFFF;
    }

    &__text {
      font-size: 17px;
      line-height: calc(26/17*1em);
    }
  }
}