.map {
  &__title {

  }

  &__container {
    width: 100%;
    position: relative;
  }

  &__map {
    filter: var(--filter-color);
    height: 850px;
  }
}

@include respond-up('large') {
  .map {
    &__title {

    }

    &__container {
      margin-top: 48px;
      border-radius: 24px;
      overflow: hidden;
      padding-bottom: calc(550/1216*100%);
    }

    &__map {

    }
  }
}

@include respond-down('medium') {
  .map {
    &__title {

    }

    &__container {

    }

    &__map {

    }
  }
}