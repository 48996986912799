.partners {
  &__title {
    color: #FFFFFF;
    font-weight: 600;
  }

  &__list {
    display: grid;
  }

  &__item {
    background: var(--background-opacity-60);
    border-radius: 16px;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
  }

  &__image {
    display: flex;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &__picture {

  }
}

@include respond-up('large') {
  .partners {
    &__title {
      font-size: 44px;
      line-height: calc(56/44*1em);
    }

    &__list {
      margin-top: 48px;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 32px;
      grid-row-gap: 40px;
    }

    &__item {

    }

    &__link {

    }

    &__image {

    }

    &__picture {

    }
  }
}

@include respond-down('medium') {
  .partners {
    &__title {
      font-size: 34px;
      line-height: calc(44/34*1em);
    }

    &__list {
      margin-top: 24px;
      grid-template-columns: 1fr;
      grid-row-gap: 12px;
    }

    &__item {

    }

    &__link {

    }

    &__image {

    }

    &__picture {
      display: flex;
      width: 100%;
      height: 100%;
      padding: 30px 10px;
    }
  }
}