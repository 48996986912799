.services-page {
  &__picture {
    position: relative;
    z-index: 5;
    display: flex;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__link {
    display: flex;
  }

  &__image-container {
    display: block;
    position: relative;
    z-index: 5;
  }

  &__image-transparent-layer {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--background-opacity-08);
  }

  &__parent-title {
    color: #fff;
    font-style: normal;
    font-weight: 600;
  }

  &__category-icon-wrapper {
    display: inline-flex;
    vertical-align: middle;
  }

  &__service-list {
    display: grid;
  }

  &__service {
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid var(--background-opacity-08);

    &_paginate {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .pagination-endless {
        width: 100%;
        height: 100%;

        &__button {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin-top: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          background: var(--background-main-gray);
          z-index: 10000;

          &:hover {
            .pagination-block {
              &__paginate-icon {
                svg {
                  path {
                    fill: var(--background-yellow);
                  }
                }
              }

              &__text {
                color: var(--background-yellow);
              }

              &__remain {
                background-color: var(--background-yellow);
              }
            }
          }
        }
      }

      .pagination-block {
        &__inner {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        &__text {
          font-size: 17px;
          font-weight: 600;
          line-height: calc(24/17*1em);
          color: #FFF;
          display: flex;
          align-items: center;
          transition: .4s color;
        }

        &__remain {
          font-size: 13px;
          font-weight: 600;
          line-height: calc(20/13*1em);
          color: #000;
          padding: 1px 6px;
          border-radius: 1000px;
          background: #FFF;
          display: block;
          height: 20px;
          width: 20px;
          transition: .4s background-color;
        }

        &__paginate-icon {
          svg {
            path {
              transition: .4s fill;
            }
          }
        }
      }
    }
  }

  &__text-container {
    background: var(--background-main-gray);
    position: relative;
    z-index: 10;
    width: 100%;
  }

  &__text, &__more {
    color: #FFF;
    font-weight: 600;
  }
}

@include respond-up('large') {
  .services-page {
    padding: 64px 0 88px;

    &__link {
      &:hover {
        .services-page {
          &__text {
            color: var(--background-yellow);
          }

          &__text-desktop {
            color: var(--background-yellow);
          }

          &__more {
            &_text {
              color: var(--background-yellow);
            }

            &_arrow {
              opacity: 1;
            }
          }
        }
      }
    }

    &__parent-title {
      font-size: 44px;
      line-height: calc(56/44*1em);
    }

    &__category-icon {
      width: 46px;
      max-width: 46px;
      height: 46px;
      max-height: 46px;
      margin-right: 20px;
    }

    &__text-container, &__link {
      height: 100%;
    }

    &__text-container {
      display: flex;
      flex-direction: column;
    }

    &__image-container {

    }

    &__section {
      &:not(:last-child) {
        margin-bottom: 72px;
      }
    }

    &__service {
      min-height: 280px;
    }

    &__service-list {
      margin-top: 45px;
      grid-row-gap: 32px;

      &.short {
        .services-page {
          &__service {
            &:nth-child(2) {
              grid-column: 7/10;
            }

            &:nth-child(3) {
              grid-column: 10/13;
            }

            &:nth-child(4n+4) {
              grid-column: 1/4;
            }

            &:nth-child(4n+5) {
              grid-column: 4/7;
            }

            &:nth-child(4n+6) {
              grid-column: 7/10;
            }

            &:nth-child(4n+7) {
              grid-column: 7/13;
            }

            &:nth-child(n+2) {
              .services-page {
                &__image-container {
                  width: 280px;
                  min-width: 280px;
                  height: 150px;
                  min-height: 150px;
                }

                &__link {
                  flex-direction: column;
                }

                &__text-container {
                  padding: 16px 20px 20px;
                  margin-top: -16px;
                  border-radius: 20px;
                  overflow: hidden;
                }

                &__text {
                  font-size: 16px;
                  line-height: calc(24/17*1em);
                }
              }
            }
          }
        }
      }

      &.long {
        .services-page {
          &__service {
            &:nth-child(2) {
              grid-column: 7/13;

              .services-page {
                &__image-container {
                  width: 240px;
                  min-width: 240px;
                  height: 280px;
                  min-height: 280px;
                }

                &__text-container {
                  padding: 24px;
                  margin-left: -12px;
                  border-radius: 20px;
                  overflow: hidden;
                }

                &__text {
                  font-size: 22px;
                  line-height: calc(32/22*1em);
                }
              }
            }

            &:nth-child(4n+3) {
              grid-column: 1/4;
            }

            &:nth-child(4n+4) {
              grid-column: 4/7;
            }

            &:nth-child(4n+5) {
              grid-column: 7/10;
            }

            &:nth-child(4n+6) {
              grid-column: 10/13;
            }

            &:nth-child(n+3) {
              .services-page {
                &__image-container {
                  width: 280px;
                  min-width: 280px;
                  height: 150px;
                  min-height: 150px;
                }

                &__link {
                  flex-direction: column;
                }

                &__text-container {
                  padding: 16px 20px 20px;
                  margin-top: -16px;
                  border-radius: 20px;
                  overflow: hidden;
                }

                &__text {
                  font-size: 16px;
                  line-height: calc(24/17*1em);
                }
              }
            }
          }
        }
      }

      &_paginate {
        .services-page {
          &__service {
            &:nth-child(5), &:nth-child(6) {
              display: none;
            }

            &_paginate {
              grid-column: 7/13 !important;
            }
          }
        }
      }
    }

    &__service {
      &:first-child {
        grid-column: 1/7;

        .services-page {
          &__image-container {
            width: 240px;
            min-width: 240px;
            height: 280px;
            min-height: 280px;
          }

          &__text-container {
            padding: 24px;
            margin-left: -12px;
            border-radius: 20px;
            overflow: hidden;
          }

          &__text {
            font-size: 22px;
            line-height: calc(32/22*1em);
          }
        }
      }
    }

    &__more {
      font-size: 13px;
      font-weight: 600;
      line-height: calc(20/13*1em);
      width: fit-content;
      display: flex;
      margin-top: auto;

      &_text {
        padding: 5px 12px 7px;
        border-radius: 8px;
        background: var(--background-opacity-08);
        transition: color 0.3s var(--default-transition-function);
      }

      &_arrow {
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px 12px;
        transition: opacity 0.3s var(--default-transition-function);
        border-radius: 8px;
        background: var(--background-opacity-08);

        svg {
          path {
            fill: var(--background-yellow);
          }
        }
      }
    }

    &__text {
      transition: color 0.3s var(--default-transition-function);
    }

    &__text-overflow {
      overflow: hidden;
    }

    &__text-desktop {
      margin-top: 10px;
      font-size: 13px;
      line-height: calc(20/13*1em);
      color: #FFFFFF;
      transition: .4s color;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }
}

@include respond-down('medium') {
  .services-page {
    padding: 16px 0 48px;

    &__text {
      font-size: 17px;
      line-height: calc(24/17*1em);
    }

    &__parent-title {
      font-size: 28px;
      line-height: calc(36/28*1em);
    }

    &__category-icon {
      width: 36px;
      max-width: 36px;
      height: 36px;
      max-height: 36px;
      margin-right: 16px;
    }

    &__service-list {
      margin-top: 16px;
      grid-row-gap: 12px;

      &:not(:last-child) {
        margin-bottom: 48px;
      }

      &_paginate {
        .services-page {
          &__service {
            &:nth-child(5), &:nth-child(6) {
              display: none;
            }
          }
        }
      }
    }

    &__image-container {

    }

    &__picture, &__image {

    }

    &__more {
      display: none;
    }

    &__text-container {
      border-radius: 16px;
      width: 100%;
    }

    &__section {
      margin-top: 48px;
    }

    &__service {
      grid-column: 1/5;

      &:first-child {
        .services-page {
          &__link {
            display: flex;
            flex-direction: column;
          }

          &__text-container {
            margin-top: -12px;
            padding: 16px 20px;
          }

          &__image-container {

          }
        }
      }

      &:not(:first-child) {
        .services-page {
          &__text-container {
            margin-left: -12px;
            padding: 20px 16px;
          }

          &__image {
            width: 104px;
            min-width: 104px;
            max-width: 104px;
            min-height: 88px;
          }
        }
      }

      &_paginate {
        min-height: 88px;

        .pagination-block {
          &__paginate-icon {
            display: none;
          }
        }
      }
    }

    &__text-overflow, &__text-desktop {
      display: none;
    }
  }
}