.arrows {
  &__wrap {
    display: flex;
    justify-content: space-between;
  }

  &__left, &__right {
    display: block;
    width: fit-content;
  }

  &__link {
    display: inline-block;

    &._arrow-inactive {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__right {

  }

  &__service-slider {
    .arrows {
      &__left, &__right {
        svg {
          width: 44px;
          height: 44px;
        }
      }

      &__wrap {
        height: 1px;
      }
    }
  }
}

@include respond-down('medium') {
  .arrows {
    &__link {
      svg {
        width: 26px;
        height: 26px;
      }
    }

    &__service-slider {
      display: none;
    }
  }
}