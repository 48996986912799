.recall-form {
  &._yellow-bg {
    .recall-form {
      &__success-title, &__success-subtitle {
        color: var(--background-main-gray);
      }
    }
  }

  &__yellow {

  }

  &__inner {
    position: relative;
  }

  &__info, &__form {
    position: relative;
    z-index: 20;
  }

  &__title {
    color: #FFFFFF;
    text-align: center;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 17px;
    line-height: calc(26/17*1em);
    color: #FFFFFF;
    padding-top: 8px;
    text-align: center;
  }

  &__image-container {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
  }

  &__info, &__fields, &__button-wrapper {
    transition: {
      property: opacity;
      duration: .1s;
    };
  }

  &__form {
    &.success {
      .recall-form {
        &__info, &__fields, &__button-wrapper {
          opacity: 0;
        }

        &__success {
          opacity: 1;
          pointer-events: all;
        }

        &__success-title, &__success-subtitle {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &__fields {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__field {
    margin-bottom: 24px;
  }

  &__button-wrapper {

  }

  &__policy {
    padding-top: 8px;
    font-weight: 400;
    font-size: 13px;
    line-height: calc(20/13*1em);
    text-align: center;
    color: #FFFFFF;
    max-width: 220px;
  }

  &__policy-link {
    border-bottom: 1px solid #FFFFFF;
  }

  &__success {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    pointer-events: none;
    transition: {
      property: opacity;
      duration: .2s;
    };
  }

  &__success-title {
    font-weight: 600;
    font-size: 28px;
    line-height: calc(36/28*1em);
    text-align: center;
    color: #FFFFFF;
    opacity: 0;
    transform: translateY(10px);
    transition: {
      property: opacity, transform;
      duration: .2s;
      delay: .2s;
    };
  }

  &__success-subtitle {
    font-weight: 400;
    font-size: 17px;
    line-height: calc(26/17*1em);
    text-align: center;
    color: var(--secondary-white-text);
    padding-top: 8px;
    opacity: 0;
    transform: translateY(10px);
    transition: {
      property: opacity, transform;
      duration: .2s;
      delay: .3s;
    };
  }

  &__button {
    font-family: var(--h-font) !important;
    background-color: #FFFFFF;
    border-radius: 10px;
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    width: 100%;
    color: #000000;
  }
}

@include respond-up('large') {
  .recall-form {
    margin-bottom: 88px;

    &__title {
      font-weight: 600;
      font-size: 44px;
      line-height: calc(56/44*1em);
    }

    &__subtitle {
      font-size: 17px;
      line-height: calc(26/17*1em);
    }

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 64px 0;
    }

    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__fields {
      margin-top: 40px;
    }

    &__field {
      width: 340px;
    }

    &__button-wrapper {
      max-width: 340px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__button {
      width: 340px;
      transition: .4s opacity;

      &:hover {
        opacity: 0.88;
      }
    }

    &__image {
      backface-visibility: hidden;
    }
  }
}

@include respond-down('medium') {
  .recall-form {
    margin-bottom: 48px;

    &__title {
      font-size: 28px;
      line-height: calc(36/28*1em);
    }

    &__subtitle {
      font-size: 17px;
      line-height: calc(26/17*1em);
    }

    &__fields {
      margin-top: 32px;
    }

    &__field {
      width: 100%;
    }

    &__inner {
      padding: 48px 16px;
    }

    &__button-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}