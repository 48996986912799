.article-part {
  &__article {
    &_green {
      a {
        color: var(--primary-green-color);
      }

      ul {
        li {
          &::before {
            background-color: var(--primary-green-color);
          }
        }
      }

      ol {
        li {
          &::before {
            color: var(--primary-green-color);
          }
        }
      }
    }

    &_pink {
      a {
        color: var(--pink-color);
      }

      ul {
        li {
          &::before {
            background-color: var(--pink-color);
          }
        }
      }

      ol {
        li {
          &::before {
            color: var(--pink-color);
          }
        }
      }
    }

    &_yellow {
      a {
        color: var(--background-yellow);
      }

      ul {
        li {
          &::before {
            background-color: var(--background-yellow);
          }
        }
      }

      ol {
        li {
          &::before {
            color: var(--background-yellow);
          }
        }
      }
    }
  }

  &__picture {
    position: relative;
    overflow: hidden;
    display: block;

    &_slider {

    }
  }

  &__images-wrapper {
    margin-top: 48px;
    position: relative;
  }

  &__vertical-image-container {
    margin-top: 48px;
  }

  &__vertical-picture {
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &__vertical-image {
    height: 100%;
    object-fit: cover;
  }

  &__image-list {
    overflow: hidden;
  }

  //&__image-item {article-part__arrowsage-item {
  //  //  &.normal {
  //  //    transition-duration: 0s !important;
  //  //    animation-duration: 0s !important;
  //  //
  //  //    .article-part {
  //  //      &__picture {
  //  //        transform: translateX(100%);
  //  //      }
  //  //
  //  //      &__image {
  //  //        transform: translateX(-100%);
  //  //      }
  //  //    }
  //  //  }
  //  //
  //  //  &.fade-in {
  //  //    transform: translateX(0);
  //  //    z-index: 2;
  //  //
  //  //    .article-part {
  //  //      &__picture {
  //  //        transform: translateX(0);
  //  //        transition: transform .9s;
  //  //      }
  //  //
  //  //      &__image {
  //  //        animation: animate-image .9s;
  //  //      }
  //  //    }
  //  //  }
  //  //
  //  //  &.fade-out {
  //  //    transform: translateX(0);
  //  //    z-index: 1;
  //  //
  //  //    .article-part {
  //  //      &__picture {
  //  //        transform: translateX(0);
  //  //        transition: transform .9s;
  //  //      }
  //  //
  //  //      &__image {
  //  //        transform: translateX(0);
  //  //        transition: transform .9s;
  //  //      }
  //  //    }
  //  //  }
  //  //}
  //  &.normal {
  //    transition-duration: 0s !important;
  //    animation-duration: 0s !important;
  //
  //    .article-part {
  //      &__picture {
  //        transform: translateX(100%);
  //      }
  //
  //      &__image {
  //        transform: translateX(-100%);
  //      }
  //    }
  //  }
  //
  //  &.fade-in {
  //    transform: translateX(0);
  //    z-index: 2;
  //
  //    .article-part {
  //      &__picture {
  //        transform: translateX(0);
  //        transition: transform .9s;
  //      }
  //
  //      &__image {
  //        animation: animate-image .9s;
  //      }
  //    }
  //  }
  //
  //  &.fade-out {
  //    transform: translateX(0);
  //    z-index: 1;
  //
  //    .article-part {
  //      &__picture {
  //        transform: translateX(0);
  //        transition: transform .9s;
  //      }
  //
  //      &__image {
  //        transform: translateX(0);
  //        transition: transform .9s;
  //      }
  //    }
  //  }
  //}

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: contain;
  }

  &__arrows {
    position: absolute;
    z-index: 50;
    top: calc(50% - 30px);
    left: -28px;
    right: -28px;
  }

  &__quote-container {
    background: var(--background-opacity-60);
    border-radius: 8px;
    margin-bottom: 32px;
  }

  &__top-container {
    display: flex;
  }

  &__author-image {
    width: 64px;
    height: 64px;
    border-radius: 48px;
    overflow: hidden;
    margin-right: 16px;

    &_default {
      background: {
        image: url('../images/base/avabomba.png');
        repeat: no-repeat;
        size: cover;
        position: center;
      }
    }
  }

  &__avatar-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__avatar-picture {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &__info-wrapper {

  }

  &__author-name {
    font-weight: 500;
    font-size: 20px;
    line-height: calc(28/20*1em);
    color: #FFFFFF;
  }

  &__author-post {
    font-weight: 400;
    font-size: 15px;
    line-height: calc(24/15*1em);
    color: var(--secondary-white-text);
  }

  &__author-text {
    font-weight: 400;
    font-size: 17px;
    line-height: calc(26/17*1em);
    color: #FFFFFF;
    margin-top: 16px;
  }

  &__counter-container {
    position: absolute;
    z-index: 50;
    font-weight: 600;
    font-size: 15px;
    line-height: calc(24/15*1em);
    color: #FFFFFF;
    padding: 12px;
    background: var(--background-opacity-64);
    border-radius: 8px;
  }

  &__image-link {
    display: block;
  }
}

@include respond-up('large') {
  .article-part {
    &__article {
      grid-column: 3/11;
    }

    &._redesign {
      .article-part {
        &__article {
          grid-column: 3/10;
        }
      }
    }

    &__picture {
      padding-top: 600 / 1104 * 100%;

      &_slider {

      }
    }

    &__images-wrapper, &__vertical-image-container  {
      grid-column: 1/13;
    }

    &__image-list {

    }

    &__image-item {

    }

    &__image {

    }

    &__arrows {

    }

    &__quote-container {
      grid-column: 3/11;
      padding: 40px;
    }

    &__top-container {
      align-items: center;
    }

    &__author-image {

    }

    &__avatar-image {

    }

    &__avatar-picture {

    }

    &__info-wrapper {

    }

    &__author-name {

    }

    &__author-post {

    }

    &__author-text {

    }

    &__counter-container {
      top: 16px;
      right: 16px;
    }
  }
}

@include respond-down('medium') {
  .article-part {
    &__article {

    }

    &__article, &__images-wrapper, &__quote-container, &__vertical-image-container {
      grid-column: 1/5;
    }

    &__picture {
      padding-top: 190 / 320 * 100%;

      &_slider {

      }
    }

    &__images-wrapper {
      .tns-inner {
        margin: 0 -16px;
      }
    }

    &__image-list {

    }

    &__image-item {

    }

    &__image {

    }

    &__arrows {
      top: calc(50% - 15px);
      left: -15px;
      right: -15px;
    }

    &__quote-container {
      padding: 16px;
    }

    &__top-container {

    }

    &__author-image {

    }

    &__avatar-image {

    }

    &__avatar-picture {

    }

    &__info-wrapper {

    }

    &__author-name {

    }

    &__author-post {

    }

    &__author-text {

    }

    &__counter-container {
      top: 8px;
      right: 8px;
    }
  }
}

@keyframes animate-image {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}