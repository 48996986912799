.footer {
  background: var(--background-opacity-60);

  &__inner {

  }

  &__logo-container {
    display: flex;
    flex-direction: column;
    grid-area: logo-block;
  }

  &__logo-link {
    display: block;
    width: fit-content;
    margin-bottom: 24px;
  }

  &__description {

  }

  &__socials-container {

  }

  &__nav-container {
    display: flex;
    flex-direction: column;
    grid-area: nav-block;
  }

  &__nav-caption {
    font-weight: 500;
    font-size: 20px;
    line-height: calc(28/20*1em);
    color: #FFFFFF;
  }

  &__nav-list {
    margin: -8px 0;
    display: flex;
    flex-direction: column;
  }

  &__nav-item {

  }

  &__nav-link {
    display: block;
    padding: 8px 0;
    font-weight: 400;
    font-size: 17px;
    line-height: calc(26/17*1em);
    color: var(--secondary-white-text);
    white-space: nowrap;
  }

  &__info-container {
    grid-area: info-block;
  }

  &__info-list {
    display: flex;
  }

  &__info-item {
    font-weight: 400;
    font-size: 15px;
    line-height: calc(24/15*1em);
    color: var(--gray-text);
  }

  &__company, &__email-link, &__policy-link {
    display: block;
    white-space: nowrap;
  }

  &__credentials-item {
    font-weight: 400;
    font-size: 15px;
    line-height: calc(24/15*1em);
    color: #FFFFFF;
  }
}

@include respond-up('large') {
  .footer {
    padding: 64px 0;

    &__inner {
      grid-template-areas:
              "logo-block nav-block"
              "info-block nav-block";
    }

    &__logo-container {
      grid-column: 1/6;
      margin-bottom: 50px;
    }

    &__info-container {
      display: flex;
      align-items: flex-end;
      grid-column: 1/8;
    }

    &__nav-container {
      grid-column: 11/13;
    }

    &__nav-caption {
      margin-bottom: 22px;
    }

    &__description {
      font-weight: 400;
      font-size: 15px;
      line-height: calc(24/15*1em);
      color: #FFFFFF;
      margin-bottom: 16px;
    }

    &__credentials {
      margin-top: 16px;
    }

    &__info-list {
      margin: 0 -12px;
    }

    &__email-link, &__policy-link, &__company {
      padding: 0 12px;
    }

    &__email-link, &__policy-link {
      transition: .4s color;

      &:hover {
        color: #FFFFFF;
      }
    }

    &__nav-link {
      transition: .4s color;

      &:hover {
        color: #FFFFFF;
      }
    }
  }
}

@include respond-down('medium') {
  .footer {
    padding: 40px 0;

    &__inner {
      grid-template-areas:
              "logo-block"
              "nav-block"
              "info-block";
    }

    &__logo-container, &__nav-container, &__info-container {
      grid-column: 1/5;
    }

    &__logo-container, &__nav-container {
      margin-bottom: 40px;
    }

    &__nav-caption {
      margin-bottom: 20px;
    }

    &__description {
      display: none;
    }

    &__info-list {
      flex-direction: column;
      margin: -8px 0;
    }

    &__item-inner {
      padding: 8px 0;
    }

    &__credentials {
      margin-top: 24px;
    }
  }
}