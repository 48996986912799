.vacancy-page {
  &__list {

  }

  &__item {

  }

  &__link {
    display: block;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.12);
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-family: var(--h-font);
    font-weight: 600;
    font-size: 28px;
    line-height: calc(36/28*1em);
    color: #FFFFFF;
  }

  &__description {
    font-weight: 400;
    font-size: 17px;
    line-height: calc(26/17*1em);
    color: var(--secondary-white-text);
    padding-top: 8px;
  }

  &__pseudo-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button-container {
    display: flex;
    align-items: center;
    min-height: max-content;
  }

  &__there-is-no-vacancies-title {
    font-family: var(--h-font);
    font-weight: 600;
    font-size: 28px;
    line-height: calc(36/28*1em);
    color: #FFFFFF;
  }

  &__there-is-no-vacancies-description {
    padding-top: 8px;
    font-weight: 400;
    font-size: 17px;
    line-height: calc(26/17*1em);
    color: var(--secondary-white-text);
    padding-bottom: 86px;
  }

  &__vk-link {
    display: inline-flex;
    color: var(--primary-green-color);
    width: fit-content;
  }
}

@include respond-up('large') {
  .vacancy-page {
    padding: 72px 0 88px;

    &__list {

    }

    &__item {

    }

    &__link {
      display: flex;
      justify-content: space-between;
      padding: 40px;
      border-radius: 24px;
      transition: .4s background-color;

      &::before {
        left: 20px;
        right: 20px;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.06);

        .vacancy-page {
          &__title {
            color: var(--primary-green-color);
          }

          &__pseudo-button {
            background: var(--primary-green-color);
          }
        }
      }
    }

    &__text-container {
      padding-right: 50px;
    }

    &__title {
      transition: .4s color;
    }

    &__description {
      transition: .4s background-color;
    }

    &__pseudo-button {
      height: 52px;
      min-width: 153px;
      width: 153px;
      font-weight: 700;
      font-size: 17px;
      line-height: calc(28/17*1em);
      color: #000000;
      background: #FFFFFF;
      border-radius: 10px;
    }

    &__there-is-no-vacancies-title {

    }

    &__there-is-no-vacancies-description {

    }

    &__vk-link {

    }
  }
}

@include respond-down('medium') {
  .vacancy-page {
    padding: 72px 0 32px;

    &__list {

    }

    &__item {

    }

    &__link {
      padding: 32px 16px;

      &::before {
        left: 0;
        right: 0;
      }
    }

    &__text-container {

    }

    &__title {

    }

    &__description {

    }

    &__pseudo-button {
      width: 100%;
      height: 40px;
      font-weight: 600;
      font-size: 15px;
      line-height: calc(24/15*1em);
      color: #FFFFFF;
      margin-top: 20px;
      background: var(--background-opacity-60);
      border-radius: 10px;
    }

    &__there-is-no-vacancies-title {

    }

    &__there-is-no-vacancies-description {

    }

    &__vk-link {

    }
  }
}