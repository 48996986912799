.recall-form-with-link {

  &__inner {
    border-radius: 24px;
    background: var(--background-opacity-08);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title, &__description {
    color: #FFF;
    text-align: center;
  }

  &__title {
    font-weight: 600;
  }

  &__description {
    font-size: 17px;
    font-weight: 400;
    line-height: calc(26/17*1em);
    text-align: center;
    margin-top: 16px;
  }

   &__recall-link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
    line-height: calc(24/15*1em);
    color: #000;
    height: 40px;
    background: var(--background-yellow);
    border-radius: 10px;
    width: 153px;
     margin-top: 32px;
  }
}

@include respond-up('large') {
  .recall-form-with-link {
    &__inner {
      padding: 48px 100px 40px;
      grid-column: 3/11;
    }

    &__title {
      font-size: 28px;
      line-height: calc(36/28*1em);
    }

    &__recall-link {
      padding: 0 16px;
      white-space: nowrap;
    }

    &__description {

    }
  }
}

@include respond-down('medium') {
  .recall-form-with-link {
    &__inner {
      padding: 24px 24px 32px;
      grid-column: 1/5;
    }

    &__title {
      font-size: 21px;
      line-height: calc(26/21*1em);
    }
  }
}