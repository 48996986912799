.pagination-endless {
  &__nav {

  }

  &__button {
    font-weight: 700;
    font-size: 17px;
    line-height: calc(28/17*1em);
    font-family: var(--h-font);
    display: flex;
    align-items: center;
    height: 52px;
    padding: 0 71px;
    color: #000000;
    background: #FFFFFF;
    border-radius: 10px;
  }

  .pagination-block {
    display: flex;
    justify-content: center;
  }
}

@include respond-up('large') {
  .pagination-endless {
    &__nav {

    }

    &__button {
      width: fit-content;
      transition: .4s opacity;
      margin-top: 64px;

      &:hover {
        opacity: 0.88;
      }
    }
  }
}

@include respond-down('medium') {
  .pagination-endless {
    &__nav {

    }

    &__button {
      margin-top: 40px;
    }
  }
}