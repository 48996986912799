.header {
  position: relative;
  z-index: 1000;
  height: var(--header-height);

  --animate-header-from: -96px;
  --animate-header-to: 0;

  &__inner {
    background: #0F0F0F;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &_sticky {
    .header {
      &__inner {
        padding: 18px 40px;
        position: fixed;
        left: 0;
        right: 0;
        box-shadow: 0 8px 10px rgba(125, 131, 146, 0.08);
        animation-name: show-header;
        animation-iteration-count: 1;
        animation-duration: 0.4s;
      }
    }
  }

  &__upper {

  }

  &__logo-link {
    display: flex;
    height: 100%;
    align-items: center;
  }

  &__logo-container {
    svg {
      backface-visibility: hidden;
    }
  }

  &__menu-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    width: 44px;
    height: 44px;
  }

  &__catalog-hamburger {
    display: block;
    position: relative;
    height: 15px;
    width: 18px;
  }

  &__hamburger-line {
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 2px;
    transition: {
      duration: .2s;
      timing-function: ease-in-out;
    };

    &_top {
      top: 0;
      transition-property: top, transform;
    }

    &_middle {
      top: 6px;
      transition-property: opacity;
    }

    &_bottom {
      bottom: 1px;
      transition-property: bottom, transform;
    }
  }

  &__lower {

  }

  &__nav-list {
    display: flex;
  }

  &__nav-item {
    display: flex;
    align-items: center;
  }

  &__nav-link {
    display: block;
    font-weight: 600;
    font-size: 17px;
    line-height: calc(24/17*1em);
    color: #FFFFFF;
  }

  &__buttons-container {

  }

  &__socials-container {

  }

  &__recall-link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
    line-height: calc(24/15*1em);
    color: #FFFFFF;
    height: 40px;
    background: var(--background-opacity-60);
    border-radius: 10px;
  }
}

@include respond-up('large') {
  .header {
    --header-height: 80px;
    display: flex;

    &__inner {
      padding: 26px 32px;
    }

    &__menu-link {
      display: none;
    }

    &__upper {
      margin-right: calc(20/1400*100vw);
    }

    &__buttons-container {
      display: flex;
      align-items: center;
    }

    &__socials-container {
      margin-right: 15px;
    }

    &__lower {
      width: 100%;
    }

    &__drop-down-container {
      display: flex;
      justify-content: space-between;
    }

    &__nav-list {
      margin: 0 -8px;
    }

    &__nav-link {
      padding: 6px 16px;
      margin: 0 8px;
      border-radius: 42px;
      white-space: nowrap;
      transition: {
        duration: .4s;
        property: background-color, color;
      };

      &._active {
        background-color: var(--background-main-gray);
        color: var(--primary-green-color);
      }

      &:hover {
        background-color: var(--background-main-gray);
        color: var(--primary-green-color);
      }

      &_pink {
        &:hover {
          color: var(--pink-color);
        }

        &._active {
          color: var(--pink-color);
        }
      }

      &_yellow {
        &:hover {
          color: var(--background-yellow);
        }

        &._active {
          color: var(--background-yellow);
        }
      }
    }

    &__recall-link {
      padding: 0 16px;
      white-space: nowrap;
    }
  }
}

@media (min-width: 1260px) and (max-width: 1380px) {
  .header {
    &__socials-container {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .header {
    --header-height: 64px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    --animate-header-from: -64px;
    --animate-header-to: 0;

    &_sticky {
      .header {
        &__inner {
          padding: 10px 16px 10px 24px;
        }
      }
    }

    &__inner {
      padding: 10px 16px 10px 24px;
    }

    &_opened-menu {
      .header {
        &__catalog-hamburger {
          width: 21px;
        }

        &__hamburger-line {
          &_top {
            top: 50%;
            transform: rotate(46deg);
          }

          &_middle {
            opacity: 0;
          }

          &_bottom {
            bottom: calc(50% - 2px);
            transform: rotate(-45deg);
          }
        }
      }
    }

    &__nav-list {
      flex-direction: column;
    }

    &__logo-link {

    }

    &__logo-container {
      display: flex;
      align-items: center;

      svg {
        height: 24px;
        width: 152px;
      }
    }

    &__nav-link {
      width: 100%;
      padding: 10px 0 14px 16px;
    }

    &__nav-list {
      padding-top: 16px;
    }

    &__buttons-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px 16px;
    }

    &__socials-container {
      order: 2;
    }

    &__recall-link {
      order: 1;
      width: 100%;
      padding: 0 16px;
    }

    &__drop-down-container {
      border-top: 1px solid var(--background-opacity-60);
    }

    &__lower {
      position: absolute;
      top: 63px;
      left: 0;
      right: 0;
      box-shadow: 0 12px 40px rgba(125, 131, 146, 0.21);
      background: var(--background-main-gray);
      height: 0;
      overflow: hidden;
      transition: {
        property: height;
        duration: .5s;
        timing-function: ease-in-out;
      };
    }

    &__upper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
}

@keyframes show-header {
  0% {
    transform: translateY(var(--animate-header-from));
    opacity: 0;
  }
  100% {
    transform: translateY(var(--animate-header-to));
    opacity: 1;
  }
}