.page-offer {
  overflow: hidden;
  position: relative;

  &__inner {
    position: relative;
    z-index: 10;
  }

  &__text-container {

  }

  &__title {

  }

  &__subtitle {
    color: #FFFFFF;
    font-weight: 500;
  }

  &__image-container {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-transparent-layer {
    position: absolute;
    z-index: 7;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--background-opacity-08);
  }
}

@include respond-up('large') {
  .page-offer {
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    height: 450px;
    margin: 0 24px;
    border-radius: 24px;
    background: var(--background-opacity-60);

    &__inner {
      border-radius: 24px;
    }

    &__text-container {
      grid-column: 5/9;
      margin: 0 -50px;
    }

    &__title, &__subtitle {
      text-align: center;
      text-shadow: 0 24px 40px rgba(0, 0, 0, 0.40);
    }

    &__subtitle {
      padding-top: 24px;
      font-size: 22px;
      font-weight: 600;
      line-height: calc(32/22*1em);
    }

    &__image-container {

    }

    &__image-wrapper {
      height: 100%;
    }
  }
}

@include respond-down('medium') {
  .page-offer {
    padding: 48px 24px 56px;
    border-radius: 20px;

    &__inner {

    }

    &__text-container {
      grid-column: 1/5;
    }

    &__title {

    }

    &__subtitle {
      padding-top: 12px;
      font-size: 17px;
      line-height: calc(26/17*1em);
      font-weight: 400;
    }

    &__image-wrapper {
      width: 100%;
      height: 100%;
    }

    &__image-container {

    }
  }
}