:root {

}

@include respond-up('large') {
    :root {
        --grid-column-gap: 32px;
    }
}

@include respond-up('medium') {
    :root {

    }
}

@include respond('medium') {
    :root {

    }
}

@include respond-down('medium') {
    :root {
        --grid-column-gap: 16px;
    }
}

@include respond-down('small') {
    :root {

    }
}