.partners-page {
  &__upper-container {

  }

  &__image-container {

  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    border-radius: 20px;
  }

  &__text-container {

  }

  &__title {

  }

  &__description {
    font-weight: 400;
    font-size: 17px;
    line-height: calc(26/17*1em);
    color: #FFFFFF;
  }

  &__company-container {

  }
}

@include respond-up('large') {
  .partners-page {
    padding: 72px 0 120px;

    &__upper-container {

    }

    &__image-container {
      grid-column: 1/6;
    }

    &__picture {
      height: fit-content;
    }

    &__image {

    }

    &__text-container {
      grid-column: 7/13;
    }

    &__title {

    }

    &__description {
      padding-top: 46px;
    }

    &__company-container {
      padding-top: 120px;
    }
  }
}

@include respond-down('medium') {
  .partners-page {
    padding: 40px 0 48px;

    &__upper-container {
      display: flex;
      flex-direction: column;
    }

    &__image-container {
      order: 2;
      margin-top: 40px;
    }

    &__picture {

    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-height: 400px;;
    }

    &__text-container {
      order: 1;
    }

    &__title {
      font-size: 30px;
    }

    &__description {
      padding-top: 24px;
    }

    &__company-container {
      padding-top: 64px;
    }
  }
}