/* This stylesheet generated by Transfonter (https://transfonter.org) on October 3, 2017 10:05 PM */

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Light.eot');
	src: local('Montserrat Light'), local('Montserrat-Light'),
		url('Montserrat-Light.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-Light.woff') format('woff'),
		url('Montserrat-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-MediumItalic.eot');
	src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
		url('Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-MediumItalic.woff') format('woff'),
		url('Montserrat-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Thin.eot');
	src: local('Montserrat Thin'), local('Montserrat-Thin'),
		url('Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-Thin.woff') format('woff'),
		url('Montserrat-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-ExtraLightItalic.eot');
	src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
		url('Montserrat-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-ExtraLightItalic.woff') format('woff'),
		url('Montserrat-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-BoldItalic.eot');
	src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
		url('Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-BoldItalic.woff') format('woff'),
		url('Montserrat-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-SemiBold.eot');
	src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
		url('Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-SemiBold.woff') format('woff'),
		url('Montserrat-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-ExtraLight.eot');
	src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
		url('Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-ExtraLight.woff') format('woff'),
		url('Montserrat-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

/*@font-face {*/
	/*font-family: 'Montserrat';*/
	/*src: url('Montserrat-ExtraBoldItalic.eot');*/
	/*src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),*/
		/*url('Montserrat-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),*/
		/*url('Montserrat-ExtraBoldItalic.woff') format('woff'),*/
		/*url('Montserrat-ExtraBoldItalic.ttf') format('truetype');*/
	/*font-weight: 800;*/
	/*font-style: italic;*/
/*}*/

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Italic.eot');
	src: local('Montserrat Italic'), local('Montserrat-Italic'),
		url('Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-Italic.woff') format('woff'),
		url('Montserrat-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Bold.eot');
	src: local('Montserrat Bold'), local('Montserrat-Bold'),
		url('Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-Bold.woff') format('woff'),
		url('Montserrat-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-LightItalic.eot');
	src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
		url('Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-LightItalic.woff') format('woff'),
		url('Montserrat-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-BlackItalic.eot');
	src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
		url('Montserrat-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-BlackItalic.woff') format('woff'),
		url('Montserrat-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-SemiBoldItalic.eot');
	src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
		url('Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-SemiBoldItalic.woff') format('woff'),
		url('Montserrat-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Regular.eot');
	src: local('Montserrat Regular'), local('Montserrat-Regular'),
		url('Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-Regular.woff') format('woff'),
		url('Montserrat-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Medium.eot');
	src: local('Montserrat Medium'), local('Montserrat-Medium'),
		url('Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-Medium.woff') format('woff'),
		url('Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-ExtraBold.eot');
	src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
		url('Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-ExtraBold.woff') format('woff'),
		url('Montserrat-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Black.eot');
	src: local('Montserrat Black'), local('Montserrat-Black'),
		url('Montserrat-Black.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-Black.woff') format('woff'),
		url('Montserrat-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-ThinItalic.eot');
	src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
		url('Montserrat-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-ThinItalic.woff') format('woff'),
		url('Montserrat-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}
