article {
  &, p, li, a {
    font-weight: 400;
    font-size: 17px;
    line-height: calc(26/17*1em);
    color: #FFFFFF;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  a {
    color: var(--primary-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
    font-weight: 600;
  }

  blockquote {
    background: var(--background-opacity-60);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 32px;

    p {
      font-weight: 400;
      font-size: 17px;
      line-height: calc(26/17*1em);
      color: #FFFFFF;
    }
  }

  ul, ol {
    margin-bottom: 16px;
  }

  ul {
    li {
      position: relative;
      padding: 12px 0 12px 19px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 20px;
        background-color: var(--background-yellow);
        width: 7px;
        height: 7px;
      }
    }
  }

  ol {
    counter-reset: heading;

    li {
      padding: 12px 0;
      display: flex;
      position: relative;

      p {
        border-bottom: 1px solid var(--color-border);
      }

      &::before {
        margin-right: 12px;
        counter-increment: heading;
        content: "" counter(heading) ". ";
        font-weight: 600;
        font-size: 17px;
        line-height: calc(24/17*1em);
        color: var(--background-yellow);
      }
    }
  }
}

@include respond-up('large') {
  article {
    h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
      margin-top: 48px;
    }

    blockquote {
      padding: 40px;
    }

    iframe {
      margin-top: 40px;
      height: 400px;
      width: 100%;
      max-width: 800px;
    }
  }
}

@include respond-down('medium') {
  article {
    h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
      margin-top: 32px;
    }

    blockquote {
      padding: 16px;
    }

    ul {
      li {
        padding: 6px 0 6px 19px;

        &::before {
          top: 15px;
        }
      }
    }

    ol {
      li {
        padding: 6px 0;
      }
    }

    iframe {
      margin-top: 30px;
      max-width: 350px;
      height: 200px;
    }
  }
}