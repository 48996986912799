.modal {
  &__closer {
    position: absolute;
    z-index: 100;
    width: 40px;
    height: 40px;
  }
}

@include respond-up('large') {
  .modal {
    &__closer {
      background-image: url('../images/svg/closer-desktop.svg');
      top: 12px;
      right: -52px;
    }
  }
}

@include respond-down('medium') {
  .modal {
    &__layout {
      height: 100%;
    }

    &__container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
    }

    &__closer {
      background-image: url('../images/svg/closer-mobile.svg');
      top: 16px;
      right: 16px;
    }
  }
}