.main-about {
  &__image-container {
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture {
    display: flex;
    height: 100%;
  }

  &__text-container {

  }

  &__title {

  }

  &__description {
    font-weight: 400;
    font-size: 17px;
    line-height: calc(26/17*1em);
    color: #FFFFFF;
    padding-top: 24px;
  }

  &__links-container {

  }

  &__about-company-link {
    font-family: var(--h-font);
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 17px;
    line-height: calc(28/17*1em);
    color: #000000;
    height: 52px;
    background: var(--background-yellow);
    border-radius: 10px;
  }

  &__partners-link {
    font-weight: 700;
    font-size: 17px;
    line-height: calc(28/17*1em);
    font-family: var(--h-font);
    display: flex;
    align-items: center;
    height: 52px;
    background: var(--background-opacity-60);
    border-radius: 10px;
  }

  &__text {
    display: block;
    margin-right: 4px;
    font-weight: 700;
    font-size: 17px;
    line-height: calc(28/17*1em);
    color: var(--background-yellow);
  }

  &__icon {
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
  }
}

@include respond-up('large') {
  .main-about {
    grid-template-areas:
            "image text"
            "image links";

    &__image-container {
      grid-area: image;
      grid-column: 1/7;
      border-radius: 20px;
    }

    &__image {

    }

    &__picture {

    }

    &__text-container {
      grid-area: text;
      grid-column: 7/13;
      align-self: end;
      padding-left: 32px;
    }

    &__title {

    }

    &__description {

    }

    &__links-container {
      grid-area: links;
      grid-column: 7/13;
      align-self: baseline;
      padding: 40px 0 0 32px;
      display: flex;
      align-items: center;
    }

    &__about-company-link {
      padding: 0 24px;
      width: fit-content;
      margin-right: 12px;
      transition: .4s opacity;

      &:hover {
        opacity: .88;
      }
    }

    &__partners-link {
      padding: 0 24px;
      width: fit-content;

      &:hover {
        .main-about {
          &__icon {
            transform: translate3d(5px, 0, 0);
          }
        }
      }
    }

    &__text {

    }

    &__icon {
      transition: .4s transform;
    }
  }
}

@include respond-down('medium') {
  .main-about {
    display: flex;
    flex-direction: column;

    &__image-container {
      border-radius: 16px;
      margin-top: 32px;
      order: 2;
      max-height: 400px;
    }

    &__image {

    }

    &__picture {

    }

    &__text-container {
      order: 1;
    }

    &__title {
      font-size: 26px;
      line-height: calc(44/34*1em);
      word-wrap: break-word;
    }

    &__description {

    }

    &__links-container {
      padding-top: 32px;
      order: 3;
    }

    &__about-company-link {
      justify-content: center;
      width: 100%;
    }

    &__partners-link {
      width: 100%;
      justify-content: center;
      margin-top: 12px;
    }

    &__text {

    }

    &__icon {

    }
  }
}