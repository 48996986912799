$grid: () !default;

/**
Этот параметр изменяемый, например на 'vw', если сайт тянется
По умолчанию - 'px'
 */
$spacer-unit: 'px';

.container {
  max-width: calc(var(--max-row-width) * 1px);
  margin: 0 auto;
}

@each $name, $data in $grid {
  @include respond-up($name) {
    @include respond($name) {
      .container {
        &_spacer {
          padding: 0 #{map-get($data, spacer)}#{$spacer-unit};
        }
      }
    }

    .container {
      &_spacer-#{$name}-up {
        padding: 0 #{map-get($data, spacer)}#{$spacer-unit};
      }
    }
  }

  @include respond-down($name) {
    .container {
      &_spacer-#{$name}-down {
        padding: 0 #{map-get($data, spacer)}#{$spacer-unit};
      }
    }
  }
}
