.floating-field {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  width: 100%;

  label {
    position: absolute;
    top: -5px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    color: var(--black-color);
    background-color: var(--background-color);
    transition: all 0.2s;
    margin: 0;
    height: calc(100% - 7px);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: -0.03em;
  }

  //&._filled label,
  //input:focus + label,
  //textarea:focus + label {
  //  color: var(--black-color);
  //  width: 100%;
  //  font-size: 15px;
  //  background-color: transparent;
  //}

  &._filled label,
  input:focus + label,
  textarea:focus + label {
    font-size: 14px;
    height: 0;
  }

  textarea {
    resize: none !important;
  }
}

@include respond-up('large') {
  .floating-field {

  }
}

@include respond('medium') {
  .floating-field {

  }
}

@include respond-down('small') {
  .floating-field {

    &._filled label,
    input:focus + label,
    textarea:focus + label,
    textarea:focus + label {
      font-size: 12px;
      height: 12px;
    }

  }
}