.offer {
  &__inner {
    position: relative;
    background: {
      size: cover;
      image: url('../images/offer/main-page-offer-bg.png');
    }
  }

  &__text-container {

  }

  &__title {

  }

  &__description {
    font-weight: 500;
    color: #FFFFFF;
  }

  &__recall-link {
    font-family: var(--h-font);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 17px;
    line-height: calc(28/17*1em);
    color: #000000;
    background: #FFFFFF;
    border-radius: 10px;
    height: 52px;
    padding: 0 24px;
  }

  &__image-container {
    position: absolute;
  }

  &__image-wrapper {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
  }

  &__black-card-image {
    position: relative;
    z-index: 5;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .offer {
    backface-visibility: hidden;
    padding: 0 24px;

    &__inner {
      border-radius: 24px;
      padding: 120px 88px;
      background: {
        position: 50% 50%;
      }
    }

    &__text-container {
      grid-column: 1/8;
    }

    &__title {
      margin-bottom: 16px;
    }

    &__description {
      font-size: 20px;
      line-height: calc(28/20*1em);
      margin-bottom: 48px;
    }

    &__recall-link {
      width: fit-content;
      transition: .4s opacity;

      &:hover {
        opacity: 0.88;
      }
    }

    &__image-container {
      top: 48px;
      right: 88px;
      width: 385px;
      height: 640px;
      //width: calc(385/1440*100vw);
      //height: calc(640/1440*100vw);
    }

    &__black-card-image {
      position: absolute;
      top: 24px;
      right: 0;
      left: 0;
      bottom: -5px;
      background: var(--background-main-gray);
      border-radius: 24px;
      transform: rotate(8deg);
    }

    &__image-wrapper {

    }

    &__picture {
      position: relative;
      z-index: 10;
      border-radius: 24px;
      overflow: hidden;
    }

    &__image {

    }

    &__mobile-image-wrapper {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .offer {
    padding-bottom: 256px;

    &__inner {
      padding: 32px 24px 56px;
      border-radius: 20px;
      background: {
        position: left;
      }
    }

    &__text-container {
      grid-column: 1/5;
    }

    &__title {
      font-size: 30px;
    }

    &__description {
      margin-top: 12px;
      font-size: 17px;
      line-height: calc(26/17*1em);
    }

    &__recall-link {
      margin-top: 32px;
    }

    &__image-container {
      bottom: -256px;
      left: 16px;
      right: 16px;
      height: 288px;
    }

    &__image-wrapper {
      display: none;
    }

    &__picture {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &__image {
      border-radius: 24px;
    }
  }
}