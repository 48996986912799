.error-page {
  background: {
    repeat: no-repeat;
    size: cover;
    position: center;
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    background: {
      image: url('../images/error/404.png');
      repeat: no-repeat;
      size: cover;
      position: center;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 21px;
  }

  &__button {
    display: flex;
    justify-content: center;
  }

  &__link {
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 267px;
    background: var(--primary-green-color);
    border-radius: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: calc(21/17*1em);
    text-align: center;
    color: var(--background-main-black);
  }
}

@include respond-up('large') {
  .error-page {
    min-height: 80vh;
    background-image: url('../images/error/background-desktop.png');

    &__inner {
      min-height: 80vh;
    }

    &__content {
      width: 700px;
      height: 517px;
    }

    &__title {
      font-size: 35px;
      line-height: calc(43/35*1em);
      white-space: nowrap;
    }

    &__button {
      padding-bottom: 62px;
    }

    &__link {

    }
  }
}

@include respond-down('medium') {
  .error-page {
    height: 80vh;
    background-image: url('../images/error/background-mobile.png');

    &__inner {
      height: 80vh;
    }

    &__content {
      position: relative;
      width: 256px;
      height: 205px;
      background-image: url('../images/error/404-mobile.png');
      //height: 100%;
    }

    &__title {
      display: none;
      font-size: 17px;
      line-height: calc(21/17*1em);
    }

    &__button {
      position: absolute;
      bottom: -70px;
    }

    &__link {

    }
  }
}